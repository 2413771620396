import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "LibroAutorPage",
    data() {
        let ss = new MainService();
        return {
            msg: "LibroAutorPage",
            ss: ss,
            ajax: {
                "url": ss.indexLibroAutor(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'Libro', name: 'Libro', title: 'Libro' },
                { data: 'Autor', name: 'Autor', title: 'Autor' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            queryLibro: "",
            queryAutor: "",

            libroautors: [],
            libroautor: {},
            libros: [],
            libro: {},
            autors: [],
            autor: {},
            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        ////////////////////////////////// Libro
        newLibro() {
            this.libro = {};
            this.libro.Activo = false;
            this.$refs['frm-libro'].show();
        },
        cancelLibro() {
            if (this.libro.id) {
                this.$refs['view-libro'].show();
            }
            this.$refs['frm-libro'].hide();
        },
        saveLibro() {
            this.ss.storeLibro(this.libro).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-libro'].hide();
                    this.$refs['datatable-libro'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        //////////////////////////////// Autor
         newAutor() {
            this.autor = {};
            this.$refs['frm-autor'].show();
        },
        cancelAutor() {
            if (this.autor.id) {
                this.$refs['view-autor'].show();
            }
            this.$refs['frm-autor'].hide();
        },
        saveAutor() {
            this.ss.storeAutor(this.autor).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-autor'].hide();
                    this.$refs['datatable-autor'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getAutor();
        },
        //////////////////////////////////////////////
        newLibroAutor() {
            this.libroautor = {};
            this.$refs['frm-libroautor'].show();
        },
        showLibroAutor(id) {
            this.isLoading=true;
            this.ss.showLibroAutor(id).then(
                (result) => {
                    let response = result.data;
                    this.libroautor = response.data;
                    this.$refs['view-libroautor'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editLibroAutor() {
            this.$refs['frm-libroautor'].show();
            this.$refs['view-libroautor'].hide();
        },
        cancelLibroAutor() {
            if (this.libroautor.id) {
                this.$refs['view-libroautor'].show();
            }
            this.$refs['frm-libroautor'].hide();
        },
        saveLibroAutor() {
            this.ss.storeLibroAutor(this.libroautor).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-libroautor'].hide();
                    this.$refs['datatable-libroautor'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteLibroAutor() {
            this.$swal({
                title: "Estas seguro que deseas eliminar?",
                text: "Esta accion es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyLibroAutor(this.libroautor)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-libroautor'].hide();
                                this.$refs['datatable-libroautor'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        /* getLibro() {
            this.ss.listLibro().then(
                (result) => {
                    let response = result.data;
                    this.libros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAutor() {
            this.ss.listAutor().then(
                (result) => {
                    let response = result.data;
                    this.autors = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, */
        draw() {
            window.$('.btn-datatable-LibroAutor').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showLibroAutor(data);
            });
        }
    },
    watch: {
        queryLibro(q) {
            this.ss.select2Libro(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.libros = res.data.items;
                } else {
                    this.libros = [];
                }
            })
        },
        queryAutor(q) {
            this.ss.select2Autor(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.autors = res.data.items;
                } else {
                    this.autors = [];
                }
            })
        }
    },
    
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }else{

            /* this.getLibro();
            this.getAutor(); */
        }
    }
};
