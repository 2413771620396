import { render, staticRenderFns } from "./AutorPage.vue?vue&type=template&id=87e7d782"
import script from "./AutorPage.js?vue&type=script&lang=js&external"
export * from "./AutorPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports