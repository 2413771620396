<template>
    <div id="app">
        <v-app id="inspire">
            <menu-externo
            v-if="
                    paginasSimples.includes(this.$router.currentRoute.name) &&
                    this.$router.currentRoute.name !== 'Login' &&
                    this.$router.currentRoute.name !== 'CompletarPerfil'
                "
            />

            <v-app-bar
                app
                color="#003f8a"
		style="height: 70px;"
                dark
		flat
                 v-if="
                    this.$router.currentRoute.name !== 'Login'
                    
                "

            >
              <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    v-if="!$vuetify.breakpoint.mdAndUp"
                >
               </v-app-bar-nav-icon>
                <v-spacer></v-spacer>  

                <!--<a class="float-left dropdown ml-2">
                <button class="button-menu-mobile waves-light waves-effect dropdown-toggle" 
                    data-toggle="dropdown" aria-expanded="true"
                    style="background-color: #ffcc00; color:#003f8a ">
                    <i class="fas fa-globe"></i>
                </button> -->
                <!-- Menú desplegable -->
                <!--<ul class="dropdown-menu p-3" style="width: 530px;">

                    <div class="row">
                        <div class="col-md-3" v-for="s in sistemas_persona_api" :key="s.id">
                            <div class="card text-center m-2" style="width: 110px; cursor: pointer;" @click="redirectToSystem(s.URL)">
                                <div class="card-body d-flex flex-column align-items-center justify-content-center">
                                    <img 
                                        src="/img/icons/emi_logo.png" 
                                        alt="" 
                                        class="card-img-top" 
                                        style="width: 70%; height: 25px; margin-bottom: 5px;"
                                    />
                                    <h6 class="card-text text-truncate" 
                                        style="color: #003f8a; font-size: 7px; max-width: 100px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                                        @{{s.Nombre}}
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </ul>
            </a>-->
		
                <v-btn icon>
                    <v-icon @click.prevent="cerrarSesion">mdi-logout</v-icon>
                </v-btn>
              </v-app-bar>   

            <v-navigation-drawer
		color="white"
                app
		:permanent="$vuetify.breakpoint.mdAndUp"  
            v-model="drawer"
                v-if="
                    this.$router.currentRoute.name !== 'Login' &&
                
    !paginasSimples.includes(this.$router.currentRoute.name)
                "
            >  

 <!-- <div  align = "center" class="text-center p-2">
                        <img src="/assets/images/SISBLI.gif" alt="" height="70" width="150">
                </div>  
-->

              <!-- desde aca  <template v-slot:prepend>
                    <v-list-item two-line>
                        <v-list-item-avatar>
                            <img :src="auth.URLFoto" />
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                auth.Persona
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                                >Sesión Iniciada</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                </template>    hasta aca -->
                <v-divider></v-divider>
                <MenuVuetify />
            </v-navigation-drawer>
            <!-- Sizes your content based upon application components -->
            <v-main>
                <!-- If using vue-router -->

                <router-view></router-view>
                <footer-externo
                    v-if="
                        paginasSimples.includes(
                            this.$router.currentRoute.name
                        ) && this.$router.currentRoute.name !== 'Login'
                    "
                />
            </v-main>
        </v-app>
    </div>
</template>

<script>
import MenuVuetify from "./partials/MenuVuetify.vue";
import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

export default {
    name: "app",
    components: {
        MenuVuetify,
        MenuExterno,
        FooterExterno,
    },
    data: () => ({
        drawer: false,
        auth: {},
        sistemas_persona_api: [], // Define la variable reactiva
        paginasSimples: [
            "Inscribete",
            "Buscador",
            "BuscadorBibliotecaVirtual",
            "Login",
            "BuscadorDiccionarioMilitar",
            "CompletarPerfil"
        ],
    }),
    mounted() {
        document.title='RUB';
        this.auth = JSON.parse(localStorage.getItem("persona"));

         // Verificamos la cookie desde el montaje del componente raíz
         if (this.getCookie('clearLocalStorageBiblioteca') === 'true') {
            // Limpiamos el localStorage
            localStorage.clear();
            const email = this.getCookie('userEmail');

            // Opcional: Eliminamos la cookie para que no persista más de una vez
            document.cookie = "clearLocalStorageBiblioteca=; path=/; domain=.emi.edu.bo; expires=Thu, 01 Jan 1970 00:00:00 GMT";

            // Redirigimos al login o página de inicio
            this.$router.push('/login?email='+email);
            //window.close();
        }

        //console.log(this.auth.email);

        // Realiza la petición cuando el componente está montado
        const apiUrl = 'https://missistemasbackend.net.emi.edu.bo/api/PersonaSistema/listSistPer?email='+this.auth.email;
            console.log(apiUrl);
            const headers = {
                Authorization: 'Bearer TU_TOKEN_AQUI',
                'Content-Type': 'application/json',
            };
    
            fetch(apiUrl, {
                method: 'GET',
                headers: headers,
            })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Error en la petición: ' + response.statusText);
                }
                return response.json(); // Parsear la respuesta como JSON
            })
            .then((data) => {
                console.log('Datos recibidos:', data); // Mostrar los datos en la consola
                this.sistemas_persona_api = data.data; // Actualiza el array reactivo
            })
            .catch((error) => {
                console.error('Error:', error); // Manejo de errores
            });
    },
    methods: {
        cerrarSesion() {
            localStorage.clear();
            this.$router.push("/Login");
            if (this.$msal.isAuthenticated()) {
                this.$msal.signOut();
            }
            this.$router.go();
        },

        redirectToSystem(url) {
                // Validar que la URL no esté vacía
                if (url && url.trim() !== "") {
                    window.open(url+"?email="+this.auth.email, '_blank'); 
                } else {
                    alert("La URL no está disponible.");
                }
            },
        // Método para obtener cookies
        getCookie(name) {
            const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
            return match ? match[2] : null;
        }
    },
};
</script>


<style lang="scss">
@import "./assets/css/app.css";
@import "./assets/scss/app.scss";
</style> 
