import { render, staticRenderFns } from "./CategoriaPage.vue?vue&type=template&id=14ec4b35"
import script from "./CategoriaPage.js?vue&type=script&lang=js&external"
export * from "./CategoriaPage.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports