var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('v-app',{attrs:{"id":"inspire"}},[(
                    _vm.paginasSimples.includes(this.$router.currentRoute.name) &&
                    this.$router.currentRoute.name !== 'Login' &&
                    this.$router.currentRoute.name !== 'CompletarPerfil'
                )?_c('menu-externo'):_vm._e(),(
                    this.$router.currentRoute.name !== 'Login'
                    
                )?_c('v-app-bar',{staticStyle:{"height":"70px"},attrs:{"app":"","color":"#003f8a","dark":"","flat":""}},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){$event.preventDefault();return _vm.cerrarSesion.apply(null, arguments)}}},[_vm._v("mdi-logout")])],1)],1):_vm._e(),(
                    this.$router.currentRoute.name !== 'Login' &&
                
    !_vm.paginasSimples.includes(this.$router.currentRoute.name)
                )?_c('v-navigation-drawer',{attrs:{"color":"white","app":"","permanent":_vm.$vuetify.breakpoint.mdAndUp},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-divider'),_c('MenuVuetify')],1):_vm._e(),_c('v-main',[_c('router-view'),(
                        _vm.paginasSimples.includes(
                            this.$router.currentRoute.name
                        ) && this.$router.currentRoute.name !== 'Login'
                    )?_c('footer-externo'):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }