import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
    name: 'LoginPage',
    data() {
        let ss = new MainService();
        return {
            ss: ss,
            loginRequest: {
                email: '',
                password: ''
            },
            errorBag: {
                email: '',
                password: ''
            },
            isSubmitting: false,  // Controlar si el botón fue presionado
            showError: false      // Controlar si el cuadro de error se muestra
        };
    },
	components: {
		MenuExterno,
		FooterExterno,
    },
	props: {
		msg: String
	},
	methods: {
        login() {
            this.isSubmitting = true;  // Marcar que el botón fue presionado
            this.showError = false;    // Ocultar cualquier error previo
            this.errorBag.password = '';  // Limpiar errores previos

            this.ss.login(this.loginRequest)
                .then((result) => {
                    this.isSubmitting = false;  // Resetear si el login es exitoso
                    localStorage.setItem('persona', JSON.stringify(result.data.data));
                    localStorage.setItem('token', JSON.stringify(result.data.token));
                    this.$router.push('/Principal');
                    this.$router.go();
                })
                .catch((error) => {
                    this.showError = true;  // Mostrar el cuadro de error

                    if (error.response && error.response.status === 401) {
                        this.errorBag.password = "Token incorrecto. Por favor, inténtelo nuevamente.";
                    } else if (error.response && error.response.data && error.response.data.errors) {
                        if (error.response.data.errors.password) {
                            const errorMsg = error.response.data.errors.password[0].replace('password', 'token');
                            this.errorBag.password = errorMsg;
                        }
                    } else {
                        this.errorBag.password = "Ocurrió un error inesperado. Intente más tarde.";
                    }
                });
        }
    },
		editPersona() {
            this.$refs['frm-persona'].show();
            this.$refs['view-persona'].hide();
        },
		authenticate() {
			if (!this.$msal.isAuthenticated()) {
				this.$msal.signIn();
			}
		
	},
	mounted() {
		var persona = JSON.parse(localStorage.getItem('persona'));

		const email = this.$route.query.email;
		const password = this.$route.query.password;

		this.loginRequest.email=email

		if(email && password) {
			this.loginRequest.email=email
			this.loginRequest.password=password
			this.login();
		}

		else {

		if (!persona) {
			localStorage.clear();
		}
		else {
			this.$router.push('/Principal');
		}
		if (this.$msal.isAuthenticated()) {
			console.log(this.$msal.data);
			let loginTokenRequest = {
				"token": this.$msal.data.user.accountIdentifier,
				"email": this.$msal.data.user.userName,
			};
			this.ss.loginToken365(loginTokenRequest)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						this.$router.push('/Libro');
						location.reload();
					}
				)
				.catch((error) => {

					this.errorBag = error.response.data.errors;
					let persona = {
						Persona:this.$msal.data.user.idToken.name,
						email:this.$msal.data.user.idToken.preferred_username,
						
					}
					//crear a la persona
					console.log("entro al error")
					this.ss.storeLogin(persona)
						.then(
							(result) => {
								console.log(result, "sebas theb");
								this.ss.loginToken365(loginTokenRequest)
								.then(
									(result) => {
										console.log(result, 'primer check');
										localStorage.setItem('persona', JSON.stringify(result.data.data));
										localStorage.setItem('token', JSON.stringify(result.data.token));
										
										this.$router.push('/CompletarPerfil');
										location.reload();
									}
								)
								.catch((error) => {
									console.log(error)
								});
							}
						)
					/*	.catch((error) => {
							this.errorBag = error.response.data.errors;
							this.$bvToast.toast(
								//'Problema al Iniciar Sesion',
								{
									title: 'Error',
									variant: 'danger',
									autoHideDelay: 5000
								}
							)
						}); */
					
					//this.ss.loginToken
					console.log(persona, 'hola');
					

				/*	this.$bvToast.toast(
						//'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)  */
					//this.$msal.signOut();

				});
		} else {
			console.log("no esta logueado")
		}
	}
}
};
