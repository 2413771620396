import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
//import { BFormRow } from "bootstrap-vue";
window.$ = window.jQuery = require("jquery");

export default {
    name: "LibroPage",
    data() {
        let ss = new MainService();
        return {
            msg: "LibroPage",
            persona: {},
            ss: ss,
			
            //<img src='+this.rutaPortada+'"/storage/documents/Alicia.jpg"
            
            ajax: {
                "url": ss.indexLibro(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N°', orderable: false, searchable: false },
                { data: 'URLPortada', searchable: false ,name: 'URLPortada', title: 'Portada', render: function(data,type ,row)
                { return '  <img src="'+row.URLPortada+'" style="width: 70%" />'} },
               /*  { data: 'Portada', name: 'Portada', title: 'Portada', render: function(data,type ,row){
                return ' <img src="'+process.env.VUE_APP_MAIN_SERVICE+'storage/documents/'+row.Portada+'" style="width: 70%" />'
                } }, */
                { data: 'Libro', name: 'l.Libro', title: 'Libro' },
                { data: 'Codigo', name: 'l.Codigo', title: 'Código' },
                { data: 'Autor', name: 'a.Autor', title: 'Autor' },
                // { data: 'Editorial', name: 'e.Editorial', title: 'Editorial' },
                // { data: 'Categoria', name: 'c.Categoria', title: 'Categoría' },
                { data: 'Reparticion', name: 'r.Reparticion', title: 'Repartición' },
                { data: 'Categoria', name: 'c.Categoria', title: 'Categoria' },
                // { data: 'TipoLibro', name: 'tl.TipoLibro', title: 'Tipo' },
                { data: 'Estado', name: 'l.Estado', title: 'Estado',  render: function(data,type ,row)
                {
                    if ( row['Estado'] == "Disponible" ) {
                        return ' <div style="color:green;" > '+row.Estado+' </div>'
                    }else if(row['Estado'] == "Ocupado"){
                        return ' <div style="color:red;" > '+row.Estado+' </div>'
                    }

                   //return ' <div style="color:green;" > '+row.Estado+' </div>'
                }},
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false

                },
                {
                    data: 'reserva',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false,
                    
                } 

            ],

            rutaApp:process.env.VUE_APP_MAIN_SERVICE,

            libros: [],
            libro: {},

            libroautors: [],
            libroautor: {},

            categorias:[],
            categoria: {},

            editoriales:[],
            editorial: {},

            reparticiones:[],
            reparticion: {},

            autors:[],
            autor: {},

            origenes:[],
            origen: {},

            tipolibros:[],
            tipolibro: {},

            infolibros:[],
            infolibro: {},

            reservas:[],
            reserva: {},

            unidadacademicas: [],
            unidadacademica: {},

            queryLibro:"",
            queryAutor:"",

            isLoading: false,
            isLoadingFile:false,
            errorBag: {}
        };
    },
    methods: {
        ////////////////////////////////////////////Mensajes MARC 21
        infoEditorial(){
            this.editorial = {};
            this.$refs['info-editorial'].show();
        },
        infoOrigen(){
            this.origen = {};
            this.$refs['info-origen'].show();
        }, 
        infoEdicion() {
            this.$refs['info-edicion'].show();
        }, 
        infoAno() {
            this.$refs['info-ano'].show();
        }, 
        infoTitulo() {
            this.$refs['info-titulo'].show();
        },
        infoDescripcion() {
            this.$refs['info-descripcion'].show();
        },
        infoCategoria() {
            this.$refs['info-categoria'].show();
        },
        infoTipoLibro() {
            this.$refs['info-tipoLibro'].show();
        }, 
        infoCodigo() {
            this.$refs['info-codigo'].show();
        }, 
        infoISBN() {
            this.$refs['info-isbn'].show();
        }, 
        infoISSN() {
            this.$refs['info-issn'].show();
        },
        infoDewey() {
            this.$refs['info-dewey'].show();
        },
        infoLibristico() {
            this.$refs['info-libristico'].show();
        },         

        ///////////////////////////////////////////Editorial
        getEditorial() {
            this.ss.listEditorial().then(
                (result) => {
                    let response = result.data;
                    this.editoriales = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        
        newEditorial() {
            this.editorial = {};
            this.$refs['frm-editorial'].show();
        },
        cancelEditorial() {
            /* if (this.editorial.id) {
                this.$refs['view-editorial'].show();
            } */
            this.$refs['frm-editorial'].hide();
        },
        filtro(){
           
            let estadoLibro = document.getElementById('EstadoLibro').value;
            let rep = this.reparticion.Reparticion;
            let cat = this.categoria.Categoria;

            if(estadoLibro == "Todos") estadoLibro = null;

            this.ajax.data = function (d) {
                 d.Estado = estadoLibro;
                 d.Reparticion = rep;
                 d.Categoria = cat;

                 console.log(d.Estado);
                 console.log('reparticion',d.Reparticion);
            }
            this.$refs['datatable-libro'].reloadAjax(this.ajax);
        },
        saveEditorial() {
            this.ss.storeEditorial(this.editorial).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-editorial'].hide();
                    this.$refs['datatable-editorial'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getEditorial()
        },
        borrarSelectEditorial(){
            this.libro.Editorial = null;
        },
        ///////////////////////////////////////////Categoria
        getCategoria() {
            this.ss.listCategoria().then(
                (result) => {
                    let response = result.data;
                    this.categorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newCategoria() {
            this.categoria = {};
            this.$refs['frm-categoria'].show();
        },
        cancelCategoria() {
            /* if (this.categoria.id) {
                this.$refs['view-categoria'].show();
            } */
            this.$refs['frm-categoria'].hide();
        },
        saveCategoria() {
            this.ss.storeCategoria(this.categoria).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-categoria'].hide();
                    this.$refs['datatable-categoria'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getCategoria()
        },
        borrarSelectCategoria(){
            this.libro.Categoria = null;
        },
        /////////////////////////////////////////// Origen
       
        getOrigen() {
            this.ss.listOrigen().then(
                (result) => {
                    let response = result.data;
                    this.origenes = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newOrigen() {
            this.origen = {};
            this.$refs['frm-origen'].show();
        },
        cancelOrigen() {
            /* if (this.origen.id) {
                this.$refs['view-origen'].show();
            } */
            this.$refs['frm-origen'].hide();
        },
        saveOrigen() {
            this.ss.storeOrigen(this.origen).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-origen'].hide();
                    this.$refs['datatable-origen'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getOrigen()
        },
        borrarSelectOrigen(){
            this.libro.Origen = null;
        },
        /////////////////////////////////////////// Tipo de Libro
        getTipoLibro() {
            this.ss.listTipoLibro().then(
                (result) => {
                    let response = result.data;
                    this.tipolibros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newTipoLibro() {
            this.tipolibro = {};
            this.$refs['frm-tipolibro'].show();
        },
        cancelTipoLibro() {
            /* if (this.tipolibro.id) {
                this.$refs['view-tipolibro'].show();
            } */
            this.$refs['frm-tipolibro'].hide();
        },
        saveTipoLibro() {
            this.ss.storeTipoLibro(this.tipolibro).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-tipolibro'].hide();
                    this.$refs['datatable-tipolibro'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getTipoLibro()
        },
        borrarSelectTipoLibro(){
            this.libro.TipoLibro = null;
        },
        ////////////////////////////////////////// Reparticion
       /*  getReparticion() {
            this.ss.listReparticion().then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        }, */

        getReparticion() {
            let params = {};
            params.UnidadAcademica = this.unidadacademica.UnidadAcademica;
            console.log(params);
            this.ss.listReparticion(params).then(
                (result) => {
                    let response = result.data;
                    this.reparticiones = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newReparticion() {
            this.reparticion = {};
            this.$refs['frm-reparticion'].show();
        },
        showReparticion(id) {
            this.isLoading=true;
            this.ss.showReparticion(id).then(
                (result) => {
                    let response = result.data;
                    this.reparticion = response.data;
                    this.$refs['view-reparticion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        cancelReparticion() {
            /* if (this.reparticion.id) {
                this.$refs['view-reparticion'].show();
            } */
            this.$refs['frm-reparticion'].hide();
        },
        saveReparticion() {
            this.ss.storeReparticion(this.reparticion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-reparticion'].hide();
                    this.$refs['datatable-reparticion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getReparticion()
        },
        borrarSelectReparticion(){
            this.libro.Reparticion = null;
        },
        ////////////////////////////////////////// Libroautor
        getLibro() {
            this.ss.listLibro().then(
                (result) => {
                    let response = result.data;
                    this.libros = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAutor() {
            this.ss.listAutor().then(
                (result) => {
                    let response = result.data;
                    this.autors = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newLibroAutor() {
            this.libroautor = {};
            this.$refs['frm-libroautor'].show();
        },
        cancelLibroAutor() {
            this.$refs['frm-libroautor'].hide();
        },
        saveLibroAutor() {
            this.ss.storeLibroAutor(this.libroautor).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-libroautor'].hide();
                    this.$refs['datatable-libroautor'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getAutor();
                this.getLibro();
        },
        //////////////////////////////// Autor
        newAutor() {
            this.autor = {};
            this.$refs['frm-autor'].show();
        },
        cancelAutor() {
            if (this.autor.id) {
                this.$refs['view-autor'].show();
            }
            this.$refs['frm-autor'].hide();
        },
        saveAutor() {
            this.ss.storeAutor(this.autor).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-autor'].hide();
                    this.$refs['datatable-autor'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
                this.getAutor();
        },
        /////////////////////////////// Cargar portada
        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.libro.Portada = result.data.data;
                            //this.saveLibro();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        borrarPortada(){
            this.libro.Portada = null;
        },
        /////////////////////////////////////Cargar Archivo
        loadFileArchivo(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            );
                            this.libro.Archivo = result.data.data;
                            //this.saveLibro();
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },
        
        newLibro() {
            this.libro = {};
            this.libro.Activo = false;
            this.$refs['frm-libro'].show();
        },
        showLibro(id) {
            this.isLoading=true;
            this.ss.showLibro(id).then(
                (result) => {
                    let response = result.data;
                    this.libro = response.data;
                    this.$refs['view-libro'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editLibro() {
            this.$refs['frm-libro'].show();
            this.$refs['view-libro'].hide();
        },
        cancelLibro() {
            if (this.libro.id) {
                this.$refs['view-libro'].show();
            }
            this.$refs['frm-libro'].hide();
        },
        saveLibro() {
            this.ss.storeLibro(this.libro).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-libro'].hide();
                    this.$refs['datatable-libro'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteLibro() {
            this.$swal({
                title: "Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyLibro(this.libro)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-libro'].hide();
                                this.$refs['datatable-libro'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        /////////////////////////////////////////////////// Reserva de Libro
        newReserva(data) {
            //this.isLoading=true;!
            console.log("desde new reserva",data);

                this.ss.storeReserva({'Libro':data, 'Persona':this.persona.id, 'Estado':2}).then(
                    (result) => {
                        console.log("Estado",this.libro.Estado);
                        //Antes de que se cree
                        let response = result.data;
                        // Después de que se cree
                        this.reserva = response.data;
    
                        console.log(response);
                        /* this.$bvToast.toast(
                            response.msg,
                            {
                                title: 'Correcto',
                                variant: 'success',
                                autoHideDelay: 5000
                            }
                        ) */
                        this.$swal(
                            {
                                    title: "Correcto",
                                    text: response.msg,
                                    icon: "success",
                                    buttons: true
                            }
                        );
                        this.enviarNotificaciones();
                    }
                ).catch(error => {
                    console.log(error);
                   // this.isLoading=false;
                });
            
        
        },
        //////////////////////////////////// Enviar notificacion al operador
        enviarNotificaciones() {
            console.log("enviar Notificación al operador!");
            this.ss.NotificacionOperador(this.reserva).then(
                (result) => {
                    console.log(result);
                    let response = result.data;

                    /* this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    ) */
                    this.$swal(
                        {
                                    title: "Correcto",
                                    text: response.msg,
                                    icon: "success",
                                    buttons: true,
                                    dangerMode: true,
                    });

                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        })
                });
        },
        draw() {
            window.$('.btn-datatable-Libro').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showLibro(data);
            });

            window.$('.btn-datatable-ReservaLibro').on('click', (evt) => {
                let data = window.$(evt.target)[0].id;
                console.log(data)
                this.newReserva(data);
            });
        }
    },
    watch: {
        queryLibro(q) {
            this.ss.select2Libro(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.libros = res.data.items;
                } else {
                    this.libros = [];
                }
            })
        },
        queryAutor(q) {
            this.ss.select2Autor(q).then((res) => {
                console.log(res);
                if (res.data.success) {
                    this.autors = res.data.items;
                } else {
                    this.autors = [];
                }
            })
        }
    },
    
    components: {
        dataTable,
        Loader
    },
    mounted() {

        this.persona = JSON.parse(localStorage.getItem('persona'));
		/* this.rutaPortada="http://127.0.0.1:8000/";
        console.log("ruta");
        console.log(this.rutaPortada); */


/*         if (!persona) {
          this.$router.push('/Login');
        }else{ */

            this.getCategoria();
            this.getTipoLibro();
            this.getEditorial();
            this.getReparticion();
            this.getOrigen();
            this.getLibro();
            this.getAutor();
            this.getUnidadAcademica();
            console.log()
            
        //}
    }
};
